<template>
  <div class="about">
    <div class="titles azjgs">
      <input-list @setData="getData" iptType="1"></input-list>
      <div class="sbSty">
        <span>设备名称：</span>
        <el-select ref="selectDev" @visible-change="$visibleChange($event, 'selectDev')" v-model="csbmc" placeholder="全部" clearable filterable>
          <el-option :label="item.name" v-for="(item, index) in csbmcArr" :key="index" :value="item.id"></el-option>
        </el-select>
      </div>
      <!-- <div>
        <span>排放状态：</span>
        <el-select v-model="status" placeholder="全部" clearable>
          <el-option :label="item.name" v-for="(item, index) in statusArr" :key="index" :value="item.id"></el-option>
        </el-select>
      </div> -->
      <div>
        <span>查询时间：</span>
        <el-date-picker v-model="timeArr" type="daterange" align="right" size="small" time-arrow-control key="daterange" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期" style="display: inline-flex; width: 210px; height: 34px" value-format="yyyy-MM-dd"> </el-date-picker>
      </div>
      <span style="display: flex; align-items: center">
        <el-button v-if="$has('viewRecordHistory')" type="success" size="mini" icon="el-icon-search" @click="searchs(1, pagesize)">查 询</el-button>
        <el-button type="info" size="mini" icon="el-icon-refresh-right" @click="reserts">重 置</el-button>
        <el-dropdown v-if="$has('exportRecordHistory')" trigger="click" @command="handleClick">
          <span class="el-dropdown-link"> <i class="el-icon-upload2" style="margin-right: 5px"></i>导出 </span>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item command="0" class="iconfont icon-excel">Excel</el-dropdown-item>
            <el-dropdown-item command="1" class="iconfont icon-pdf">PDF</el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </span>
    </div>
    <div class="cont">
      <el-table id="pdfDom" ref="table" :data="tableData" height="calc(100% - 40px)">
        <el-table-column :sortable="item.id > 4" v-for="item in tableArr" :key="item.index" :prop="item.prop" :label="item.label" :width="formatWidth(item)" :formatter="formatter" show-overflow-tooltip> </el-table-column>
        <!-- <el-table-column label="操作">
          <template slot-scope="{ row }">
            <el-button @click="openDialog(row)" size="small" icon="el-icon-tickets">详 情</el-button>
          </template>
        </el-table-column> -->
        <div slot="empty" class="empty">
          <img src="../../public/home/noData.svg" width="320" alt="" />
          <span>暂无数据</span>
        </div>
      </el-table>
      <el-pagination background @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="currpage" :page-sizes="[10, 20, 100, 500, 1000, 5000, 10000]" :page-size="pagesize" layout="total, sizes, prev, pager, next, jumper" :total="totals"> </el-pagination>
      <ToTop />
      <!-- 详情模态框 -->
      <!-- <el-dialog title="数据详情" :visible.sync="dialogVisible" width="30%" top="9vh" :modal="true" :close-on-click-modal="false" v-dialogDrag @close="cancles" center>
        <div class="dialogsC">
          <div class="newDialog detialsSty">
            <div v-for="item in detialsArr" :key="item.index">
              <span>{{ item.label }}：</span>
              <span>{{ paramsObj[item.prop] }}</span>
            </div>
          </div>
        </div>
        <span slot="footer" class="dialog-footer">
          <el-button @click="cancles">关 闭</el-button>
        </span>
      </el-dialog> -->
    </div>
  </div>
</template>
<script>
import { deviceQueryWithPage, radRecordDischargeDownloadExcel, radRecordDischargeQuery } from '@/util/http'
import inputList from '@/components/inputList/inputList.vue'
import ToTop from '@/components/toTop/toTop.vue'
// 导出 图标
import '../../public/icon/iconfont.css'
export default {
  components: { inputList, ToTop },
  data() {
    return {
      currpage: 1,
      pagesize: 10,
      totals: 0,
      tableData: [],
      cgxqy: '', // 区域
      cjgmc: '', // 机构
      csbmc: '', // 设备
      cjgmcArr: [], // 机构数组
      csbmcArr: [], // 设备数组
      timeArr: ['', ''], //日期
      tableArr: [
        { id: '0', prop: 'areaName', label: '区域名称' },
        { id: '1', prop: 'organizationName', label: '机构名称' },
        { id: '2', prop: 'deviceName', label: '设备名称' },
        // { id: '3', prop: 'pump', label: '启动泵类型' },
        { id: '3', prop: 'type', label: '衰变槽类型' },
        { id: '4', prop: 'dose', label: '衰变槽排放时剂量率(μSv/h)' },
        // { id: '5', prop: 'beforeHeight', label: '衰变槽排放前高度(m)' },
        // { id: '6', prop: 'afterHeight', label: '衰变槽排放后高度(m)' },
        // { id: '7', prop: 'sbcywc', label: '前后液位差(m)' },
        { id: '8', prop: 'createDt', label: '开始时间' },
        // { id: '9', prop: 'endDt', label: '排放结束时间' },
      ], //表格字段
      detialsArr: [
        { prop: 'areaName', label: '区域名称' },
        { prop: 'organizationName', label: '机构名称' },
        { prop: 'deviceName', label: '设备名称' },
        { prop: 'pump', label: '启动泵类型' },
        { prop: 'type', label: '衰变槽类型' },
        { prop: 'status', label: '排放状态' },
        { prop: 'beforeHeight', label: '衰变槽排放前高度(m)' },
        { prop: 'afterHeight', label: '衰变槽排放后高度(m)' },
        { prop: 'sbcywc', label: '前后液位差(m)' },
        { prop: 'startDt', label: '排放开始时间' },
        { prop: 'endDt', label: '排放结束时间' },
        { prop: 'createDt', label: '产生时间' },
        { prop: 'updateDt', label: '更新时间' },
      ],
      user: this.$store.state.user,
      status: '',
      loadingText: {
        lock: true,
        text: '拼命加载中......',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.5)',
      },
      paramsObj: {},
      dialogVisible: false,
      statusArr: [
        { id: 1, name: '排放中' },
        { id: 2, name: '排放完成' },
      ],
      pumpArr: ['排水泵1', '排水泵2', '排水泵3', '排水泵4', '排水泵5', '排水泵6'],
      htmlTitle: '',
    }
  },
  watch: {
    timeArr(val) {
      if (!val) {
        this.timeArr = ['', '']
      }
    },
  },
  // computed: {
  //   // 异常id
  //   exceptionId() {
  //     return this.$store.state.exceptionId
  //   },
  // },
  methods: {
    // 拿值区域和机构
    getData(msg) {
      this.cgxqy = msg[0]
      this.cjgmc = msg[1]
      this.csbmc = ''
      this.csbmcArr = []
      if (msg[0] && msg[1]) {
        let params = {
          areaId: this.cgxqy,
          organizationId: this.cjgmc,
          pageNo: 1,
          pageSize: 10000,
          type: 20,
        }
        deviceQueryWithPage(params).then((data) => {
          this.csbmcArr = data.result.records
          // if (this.exceptionId.length) {
          //   this.csbmc = this.exceptionId[2]
          //   this.searchs(1, 10)
          // }
        })
      }
    },
    // 分页
    handleSizeChange(val) {
      this.pagesize = val
      this.searchs(this.currpage, val)
    },
    handleCurrentChange(val) {
      this.currpage = val
      this.searchs(val, this.pagesize)
    },
    // 查询
    async searchs(currpage, pagesize) {
      const loading = this.$loading(this.loadingText)
      this.currpage = currpage
      let params = {
        queryBeginDt: this.timeArr[0],
        queryEndDt: this.timeArr[1],
        areaId: this.cgxqy,
        organizationId: this.cjgmc,
        deviceId: this.csbmc,
        status: this.status,
        pageNo: currpage,
        pageSize: pagesize,
        // id: this.exceptionId[3],
      }
      await radRecordDischargeQuery(params)
        .then((data) => {
          this.tableData = data.result.records
          if (this.tableArr[7].prop == 'sbcywc') {
            this.tableData.forEach((item) => {
              item.sbcywc = (item.beforeHeight - item.afterHeight).toFixed(3)
            })
          }
          if (this.tableArr[7].prop == 'lscywc') {
            this.tableData.forEach((item) => {
              item.lscywc = (item.troughAfterHeight - item.troughBeforeHeight).toFixed(3)
            })
          }
          this.totals = data.result.total
          loading.close()
        })
        .catch(() => {
          loading.close()
        })
    },
    // 展开模态框
    // openDialog(row) {
    //   this.paramsObj = Object.assign({}, row)
    //   this.paramsObj.status = this.statusArr[row.status - 1].name
    //   this.paramsObj.type = '槽' + row.type
    //   if (this.paramsObj.pump) {
    //     this.paramsObj.pump = this.pumpArr[row.pump - 1]
    //   }
    //   this.paramsObj.sbcywc = (row.beforeHeight - row.afterHeight).toFixed(3)
    //   this.paramsObj.lscywc = (row.troughAfterHeight - row.troughBeforeHeight).toFixed(3)

    //   this.dialogVisible = true
    // },
    // 关闭模态框
    // cancles() {
    //   this.paramsObj = {}
    //   this.dialogVisible = false
    // },
    // 格式化宽度
    formatWidth(item) {
      let formatWidth = ['startDt', 'endDt', 'createDt', 'updateDt']
      if (formatWidth.includes(item.prop)) {
        return '150'
      } else {
        return 'auto'
      }
    },
    // 格式化表格
    formatter(row, column) {
      if (column.property == 'status') {
        return this.statusArr[row[column.property] - 1].name
      }
      if (column.property == 'type') {
        return '槽' + row[column.property]
      }
      if (column.property == 'pump') {
        return this.pumpArr[row[column.property] - 1]
      }
      // if (column.property == 'sbcywc') {
      //   return (row.beforeHeight - row.afterHeight).toFixed(3)
      // }
      // if (column.property == 'lscywc') {
      //   return (row.troughAfterHeight - row.troughBeforeHeight).toFixed(3)
      // }

      return row[column.property]
    },
    // 重置
    reserts() {
      this.currpage = 1
      this.pagesize = 10
      this.$store.commit('EXCEPTIONID', [])
      this.$bus.$emit('clickResert', 1)
      this.cgxqy = this.user.areaId
      this.cjgmc = this.user.organizationId
      this.timeArr = ['', '']
      this.csbmc = ''
      this.status = ''
      this.cjgmcArr = []
      this.csbmcArr = []
      this.searchs(1, 10)
    },
    // 导出
    handleClick(e) {
      if (e == 0) {
        // excel
        this.exportes()
      } else {
        // pdf
        this.htmlTitle = `${this.csbmc}衰变槽排水记录数据报告`
        this.getPdf()
      }
    },
    // 导出Excel
    exportes() {
      let params = {
        queryBeginDt: this.timeArr[0],
        queryEndDt: this.timeArr[1],
        areaId: this.cgxqy,
        organizationId: this.cjgmc,
        deviceId: this.csbmc,
        // id: this.exceptionId[3],
        // areaId: this.cgxqy || this.exceptionId[0],
        // organizationId: this.cjgmc || this.exceptionId[1],
        // deviceId: this.csbmc || this.exceptionId[2],
        // id: this.exceptionId[3],
        status: this.status,
        pageNo: 1,
        pageSize: 1000000,
      }
      radRecordDischargeDownloadExcel(params).then((res) => {
        if (res) {
          const blob = new Blob([res])
          var a = document.createElement('a')
          a.href = URL.createObjectURL(blob)
          a.download = '衰变槽排水记录.xlsx'
          a.style.display = 'none'
          document.body.appendChild(a)
          a.click()
          a.remove()
        }
      })
    },
  },
  mounted() {
    // if (!this.exceptionId.length) {
    this.searchs(1, 10)
    // }
  },
  beforeDestroy() {
    this.$store.commit('EXCEPTIONID', [])
  },
}
</script>
<style src="../../public/css/search.less" lang="less" scoped></style>
<style lang="less" scoped>
.cont {
  width: 100%;
}
.sbSty {
  /deep/.el-input--suffix {
    width: auto;
  }
}
.detialsSty {
  > div {
    display: flex;
    align-items: center;
    > :last-child {
      width: 380px;
      height: 30px;
      display: flex;
      align-items: center;
      border: 1px solid #aaa;
      background: #eee;
      padding-left: 8px;
      box-sizing: border-box;
      color: #aaa;
      border-radius: 4px;
      margin: 5px 0;
      cursor: not-allowed;
    }
  }
}
</style>
